import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Image, InfiniteScroll, Toast } from 'antd-mobile-v5'
import RejectModal from '@/components/rejectModal'
import { getServiceActivity, activityToExamine } from '@/apis/activity'
import { showConfirm } from '@/utils'

const statusList = [
    { id: 1, name: '待审核' },
    { id: 2, name: '已驳回' },
    // { id: 3, name: '招募中' },
    { id: 4, name: '进行中' },
    // { id: 5, name: '已完成' },
    { id: 6, name: '已结束' },
]

const ActivityList3 = () => {
    const { push } = useHistory()
    const [status, setStatus] = useState(1)
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const rejectModalRef = useRef()
    const rejectId = useRef()

    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [status])

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getServiceActivity({
                page: _page,
                limit: 10,
                status
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const setExamine = async(id, status, msg) => {
        try {
            const { data } = await activityToExamine({
                id: id,
                status,
                examine_msg: msg
            })
            Toast.show('操作成功')
            page.current = 1
            setList([])
            setHasMore(true)
        } catch (e) {
            console.log(e)
        }
    }

    const onOk = async(id) => {
        const res = await showConfirm({
            title: '提示',
            content: '是否确认通过？'
        })
        if (!res) return
        setExamine(id, 1)
    }

    return (
        <div>
            <div className="content-card">
                <div className="status-tabs">
                    {statusList.map((item) => (
                        <div
                            key={item.id}
                            className={`item ${status === item.id ? 'active' : ''}`}
                            onClick={() => setStatus(item.id)}
                        >
                            <span className="item-name">{item.name}</span>
                        </div>
                    ))}
                </div>
                <div className="activity-list">
                    {list.map(item => (<div className="item-content" key={item.id}>
                        <div className="flex">
                            <div className="item-media flex-shrink-0">
                                <Image className="item-img" src={item.cover} />
                                {item.heat > 0 && <div className="item-hot flex-a-center">
                                    <div className="icon-hot"></div>
                                    <div>{item.heat}</div>
                                </div>}
                            </div>
                            <div className="item-inner flex-1">
                                <div className="flex flex-jsb">
                                    <div className="item-username flex-a-center">
                                        <img className="item-username-img" src={item.avatar} />
                                        <div className="item-username-text">@{item.nickname} {item.mobile}</div>
                                    </div>
                                    {[1, 2].includes(item.status) && <div className="item-status erro">{item.status_html}</div>}
                                    {item.status === 4 && <div className="item-status succ">{item.status_html}</div>}
                                    {item.status === 6 && <div className="item-status">{item.status_html}</div>}
                                </div>
                                <div className="item-title ell1">{item.title}</div>
                                {[1,2].includes(item.status)
                                    ? <div className="item-subtl">· {item.publish_date}</div>
                                    : <div className="item-subtl flex-a-center"><span className="icon-time"></span>{item.end_time}结束</div>}
                                <div className="item-subtl">· {item.business_district_name}</div>
                                {item.status === 2 && <div className="item-desc">{item.examine_msg}</div>}
                                {item.involved_num > 0 && <div className="item-user flex-a-center">
                                    <div className="item-user-imgs">
                                        {item.involved_num > 3 && <div className="item-user-more">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>}
                                        {item.involved_user_list.map(t => (<img key={t.id} className="item-user-img" src={t.avatar} />))}
                                    </div>
                                    <div className="item-user-text">{item.involved_num}人已参加</div>
                                </div>}
                                {[4,6].includes(item.status) && <div className="item-text">转发{item.share_num} · 裂变{item.create_finds_num} · 观看{item.watch_num}</div>}
                            </div>
                        </div>
                        {item.status === 4 && <div className="item-btns">
                            <div className="item-btn main" onClick={() => push(`/joinStoreList?id=${item.id}`)}>
                                处理
                                {item.no_examine_num > 0 && <span className="num">{item.no_examine_num}</span>}
                            </div>
                        </div>}
                        {item.status === 1 && item.platform_examine_status === 0 && <div className="item-btns">
                            <div className="item-btn" onClick={() => {
                                rejectId.current = item.id
                                rejectModalRef.current && rejectModalRef.current.show()
                            }}>驳回</div>
                            <div className="item-btn main" onClick={() => onOk(item.id)}>通过</div>
                        </div>}
                        {item.status === 6 && <div className="item-footer flex">
                            <div className="item-tag">
                                <div className="item-tag__text">报名商家<span className="num">{item.store_num}</span></div>
                                <div className="item-tag__line"></div>
                                <div className="item-tag__text">用券量<span className="num">{item.use_coupon_num}</span></div>
                            </div>
                        </div>}
                    </div>))}
                </div>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            </div>
            <RejectModal ref={rejectModalRef} onConfirm={(v, hide) => {
                setExamine(rejectId.current, 2, v).finally(() => {
                    hide()
                })
            }} />
        </div>
    )
}

export default ActivityList3