import axios from 'axios'
import http from './request'

// 提交商家入驻申请
export const addStoreAudit = (params) => http.post('/wechat/user/add-store-audit', params)

// 提交个人入驻申请
export const addPersonAudit = (params) => http.post('/wechat/user/add-person-audit', params)

// 上传图片
export const uploadImages = (params) => http.post('/store/upload/upload-images', params)

export const wxConfig = async(jsApiList = []) => {
    if (window.location.hostname != 'mstore.dzans.cn') return
    try {
        const { data } = await http.get('/store/mobile-order/get-signature', {
            url: window.location.href
        })
        window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timeStamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature,// 必填，签名
            jsApiList: jsApiList // 必填，需要使用的JS接口列表
        });
    } catch (e) {
        console.log(e)
    }
}

// 获取经纬度
export const getLngLat = async({ city, area, address }) => {
    try {
        const { data } = await axios.get(`https://restapi.amap.com/v3/geocode/geo?key=75060fae7d3d453c790b609a312a7a08&address=${city + area + address}&city=${city}`)
        const d = data.geocodes[0].location.split(',')
        return {
            lng: d[0],
            lat: d[1]
        }
    } catch (e) {
        console.log(e)
        return Promise.reject(e)
    }
}