import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CascadePicker, Picker, Toast } from 'antd-mobile-v5'
import { RightOutline } from 'antd-mobile-icons'
import Upload from '@/components/upload'
import ZFooterBar from '@/components/zFooterBar'
import region from '@/utils/address'
import { getLngLat } from '../../apis/common'
import './index.scss'
import { handleImg, isPhone, verification } from '../../utils'
import { addSonStore, getCategoryList } from '../../apis/store'

const typeList = [
    { id: 0, name: '实物商品' },
    { id: 1, name: '电子卡券' },
]

const AddSubStore = () => {
    const history = useHistory()
    const [categorys, setCategorys] = useState([]) // 平台分类
    const [fileList, setFileList] = useState([])
    const [visible, setVisible] = useState(false)
    const [regionValue, setRegionValue] = useState([])
    const [form, setValue] = useState({
        mobile: '',
        short_name: '',
        store_category_id: '',
        product_type: 0,
        average_spend: '',
        lowest_delivery_price: '',
        province: '',
        city: '',
        area: '',
        address: '',
        lng: '',
        lat: '',
        hot_line: '',
        cover: '',
        notice: '',
        contact_name: '',
        contact_mobile: '',
        store_password: '',
    })

    useEffect(() => {
        getCategorys()
    }, [])

    const setFormValue = (key, value) => {
        setValue({
            ...form,
            [key]: value
        })
    }
    const setFormValues = (newValue) => {
        setValue({
            ...form,
            ...newValue
        })
    }

    // 平台分类
    const getCategorys = async () => {
        try {
            const { data } = await getCategoryList()
            setCategorys(data)
        } catch (e) {
            console.log(e);
        }
    }

    const rule = [
        {
            rule: !form.short_name,
            title: '请输入店铺简称'
        },
        {
            rule: !form.store_category_id,
            title: '请选择商家类目'
        },
        {
            rule: !form.average_spend,
            title: '请输入人均消费'
        },
        {
            rule: !form.lowest_delivery_price,
            title: '请输入起送价'
        },
        {
            rule: !form.province,
            title: '请选择店铺地址'
        },
        {
            rule: !form.address,
            title: '请输入详细地址'
        },
        {
            rule: !form.hot_line,
            title: '请输入客服手机号'
        },
        {
            rule: !isPhone(form.hot_line),
            title: '客服手机号格式不正确'
        },
        {
            rule: !fileList.length,
            title: '请上传店铺头像'
        },
        {
            rule: !form.contact_name,
            title: '请输入联系人'
        },
        {
            rule: !form.contact_mobile,
            title: '请输入联系人手机号'
        },
        {
            rule: !isPhone(form.contact_mobile),
            title: '联系人手机号格式不正确'
        },
        {
            rule: !form.mobile,
            title: '请输入店铺账号'
        },
        {
            rule: !form.store_password,
            title: '请输入店铺密码'
        },
    ]

    const onSubmit = async() => {
        if (!verification(rule)) return
        try {
            const { lng, lat } = await getLngLat({
                city: form.city,
                area: form.area,
                address: form.address
            })
            const { data } = await addSonStore({
                ...form,
                cover: handleImg(fileList),
                lng,
                lat,
            })
            Toast.show('提交成功')
            history.goBack()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="addSubStore">
            <div className="content">
                <div className="item-cell flex">
                    <div className="item-label flex-shrink-0">店铺简称<span className="required">*</span></div>
                    <div className="item-inner border flex-1">
                        <input
                            type="text"
                            className="item-input"
                            placeholder="请填写子店铺账号"
                            value={form.short_name}
                            onChange={e => setFormValue('short_name', e.target.value)}
                        />
                    </div>
                </div>
                <Picker
                    columns={[categorys]}
                    value={[form.store_category_id]}
                    onConfirm={v => {
                        setFormValue('store_category_id', v[0])
                    }}
                >
                    {(items, actions) => (
                        <div className="item-cell flex" onClick={actions.open}>
                            <div className="item-label flex-shrink-0">商家类目<span className="required">*</span></div>
                            <div className="item-inner border flex-1 flex-a-center">
                                <input type="text" className="item-input" placeholder="请选择" readOnly value={items[0] ? items[0].label : ''} />
                                <RightOutline fontSize={14} color="#8C8C93" />
                            </div>
                        </div>
                    )}
                </Picker>
                <div className="item-cell flex">
                    <div className="item-label flex-shrink-0">店铺类型<span className="required">*</span></div>
                    <div className="item-inner border flex-1 flex flex-end">
                        {typeList.map(item => (
                            <div
                                key={item.id}
                                className={`item-radio ${form.product_type === item.id ? 'active' : ''}`}
                                onClick={() => setFormValue('product_type', item.id)}
                            >
                                <span className={`icon-radio ${form.product_type === item.id ? 'active' : ''}`}></span>
                                <span>{item.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="item-cell flex">
                    <div className="item-label flex-shrink-0">人均消费<span className="required">*</span></div>
                    <div className="item-inner border flex-1 flex">
                        <input
                            type="number"
                            className="item-input"
                            placeholder="请输入人均消费"
                            value={form.average_spend}
                            onChange={e => setFormValue('average_spend', e.target.value)}
                        />
                        <div className="item-after">元</div>
                    </div>
                </div>
                <div className="item-cell flex">
                    <div className="item-label flex-shrink-0">起送价<span className="required">*</span></div>
                    <div className="item-inner border flex-1 flex">
                        <input
                            type="number"
                            className="item-input"
                            placeholder="请输起送价"
                            value={form.lowest_delivery_price}
                            onChange={e => setFormValue('lowest_delivery_price', e.target.value)}
                        />
                        <div className="item-after">元</div>
                    </div>
                </div>
                <div className="item-cell flex border">
                    <div className="item-label flex-shrink-0">店铺地址<span className="required">*</span></div>
                    <div className="flex-1">
                        <div className="item-inner border flex-a-center" onClick={() => setVisible(true)}>
                            <input
                                type="text"
                                className="item-input"
                                placeholder="请选择省/市/区"
                                readOnly
                                value={form.province ? `${form.province} ${form.city} ${form.area}` : ''}
                            />
                            <RightOutline fontSize={14} color="#8C8C93" />
                        </div>
                        <div className="item-inner">
                            <input
                                type="text"
                                className="item-input"
                                placeholder="请输入详细地址（楼栋门牌号）"
                                value={form.address}
                                onChange={e => setFormValue('address', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="item-cell flex">
                    <div className="item-label flex-shrink-0">客服手机号<span className="required">*</span></div>
                    <div className="item-inner border flex-1">
                        <input
                            type="tel"
                            className="item-input"
                            placeholder="请输入客服手机号"
                            maxLength={11}
                            value={form.hot_line}
                            onChange={e => setFormValue('hot_line', e.target.value)}
                        />
                    </div>
                </div>
                <div className="item-cell">
                    <div className="item-label">店铺头像<span className="required">*</span></div>
                    <Upload
                        value={fileList}
                        type="image"
                        style={{ paddingBottom: 0 }}
                        onChange={setFileList}
                    />
                </div>
                <div className="item-cell">
                    <div className="item-label">店铺公告</div>
                    <textarea
                        className="item-textarea"
                        placeholder="请填写公告内容"
                        value={form.notice}
                        onChange={e => setFormValue('notice', e.target.value)}
                    ></textarea>
                </div>
                <div className="item-cell flex">
                    <div className="item-label flex-shrink-0">联系人<span className="required">*</span></div>
                    <div className="item-inner border flex-1">
                        <input
                            type="text"
                            className="item-input"
                            placeholder="请输入联系人姓名"
                            value={form.contact_name}
                            onChange={e => setFormValue('contact_name', e.target.value)}
                        />
                    </div>
                </div>
                <div className="item-cell flex">
                    <div className="item-label flex-shrink-0">手机号<span className="required">*</span></div>
                    <div className="item-inner border flex-1">
                        <input
                            type="tel"
                            className="item-input"
                            placeholder="请输入联系人手机号"
                            maxLength={11}
                            value={form.contact_mobile}
                            onChange={e => setFormValue('contact_mobile', e.target.value)}
                        />
                    </div>
                </div>
                <div className="item-cell flex">
                    <div className="item-label flex-shrink-0">店铺账号<span className="required">*</span></div>
                    <div className="item-inner border flex-1">
                        <input
                            type="tel"
                            className="item-input"
                            placeholder="请输入子店铺账号"
                            maxLength={11}
                            value={form.mobile}
                            onChange={e => setFormValue('mobile', e.target.value)}
                        />
                    </div>
                </div>
                <div className="item-cell flex">
                    <div className="item-label flex-shrink-0">店铺密码<span className="required">*</span></div>
                    <div className="item-inner border flex-1">
                        <input
                            type="text"
                            className="item-input"
                            placeholder="请输入子店铺密码"
                            value={form.store_password}
                            onChange={e => setFormValue('store_password', e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <ZFooterBar>
                <div className="footer-btn flex-1" onClick={onSubmit}>提交</div>
            </ZFooterBar>
            <CascadePicker
                title='所在地区'
                options={region}
                value={regionValue}
                visible={visible}
                onClose={() => setVisible(false)}
                onConfirm={(val, extend) => {
                    const items = extend.items
                    setRegionValue(val)
                    setFormValues({
                        province: items[0].label,
                        city: items[1].label,
                        area: items[2].label,
                    })
                }}
            />
        </div>
    )
}

export default AddSubStore