import React, { useImperativeHandle, useState } from 'react'
import { Toast } from 'antd-mobile-v5'
import ZModal from '../zModal'
import './index.scss'

const RejectModal = React.forwardRef(({ title = '驳回意见', placeholder = '请输入审核意见', buttonText, onConfirm }, ref) => {
    const [value, setValue] = useState('')
    const [visible, setVisible] = useState(false)

    useImperativeHandle(ref, () => {
        return {
            show: () => {
                setValue('')
                setVisible(true)
            }
        }
    })
    const onHide = () => {
        setVisible(false)
    }

    const _onConfirm = () => {
        if (!value) return Toast.show(placeholder);
        onConfirm && onConfirm(value, onHide)
    }

    return (
        <ZModal
            title={title}
            className="reject-modal"
            visible={visible}
            isClose={false}
            showButton
            buttonText={buttonText}
            onClose={() => setVisible(false)}
            onConfirm={_onConfirm}
        >
            <div className="reject-modal-textarea">
                <textarea value={value} placeholder={placeholder} maxLength={200} onChange={e => setValue(e.target.value)}></textarea>
                <span className="len">{value.length}/200</span>
            </div>
        </ZModal>
    )
})

export default RejectModal