import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Image, ImageViewer } from 'antd-mobile-v5'
import ZFooterBar from '../../components/zFooterBar'
import ZTimer from '../../components/zTimer'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import { uuid } from '../../utils'
import qs from 'query-string'
import { getAiAnswer, getAiCoupon, getAiProduct } from '../../apis/ai'
import './index.scss'

const Ai = () => {
    const { info } = mainStore
    const { push } = useHistory()
    const { search } = useLocation()
    const { type } = qs.parse(search)
    const [content, setContent] = useState('')
    const [list, setList] = useState([])
    const inputRef = useRef()

    useEffect(() => {
        if (!info.id) {
            mainStore.getInfo()
        }
        if (type != 1) {
            document.title = 'AI帮你写活动'
        }
    }, [])

    // 获取ai数据
    const getAiData = async(content, aiData) => {
        try {
            if (type == 1) { // ai问答
                const { data } = await getAiAnswer({ content: content })
                aiData.content = data.msg
            } else if (type == 2) { // 数字券
                const { data } = await getAiCoupon({ content: content })
                aiData.content = data.msg.replace(/\\n/g, '<br />').replace(/\n/g, '<br />')
                aiData.data = data
            } else if (type == 3) { // 商品
                const { data } = await getAiProduct({ content: content })
                aiData.content = data.msg.replace(/\\n/g, '<br />').replace(/\n/g, '<br />')
                aiData.data = data
            }
        } catch (e) {
            console.log(e)
            aiData.content = '服务器繁忙，请稍后重试'
            aiData.error = true
        }
        setList(v => {
            v = [...v]
            const index = v.findIndex(t => t.id === aiData.id)
            if (index != -1) {
                v.splice(index, 1, {
                    ...aiData,
                    loading: false,
                    endTime: Date.now()
                })
            }
            return v
        })
    }

    // 跳转到底部
    const toBottom = () => {
        document.querySelector('#bottom').scrollIntoView({ behavior: 'smooth' })
    }

    // input 回车
    const onConfirm = () => {
        if (!content) return
        const aiData = {
            id: uuid(),
            type: type,
            title: content,
            content: null,
            loading: true,
            startTime: Date.now(),
            endTime: Date.now(),
        }
        setList([
            ...list,
            {
                id: uuid(),
                content: content,
                isMe: true
            },
            aiData
        ])
        setContent('')
        toBottom()
        getAiData(content, aiData)
    }

    // 重新生成
    const onRefresh = (item) => {
        const aiData = {
            id: uuid(),
            type: item.type,
            title: item.title,
            content: null,
            loading: true,
            startTime: Date.now(),
            endTime: Date.now(),
        }
        setList([
            ...list,
            aiData
        ])
        toBottom()
        getAiData(item.title, aiData)
    }

    const toPublish = (item) => {
        if (item.type == 2) { // 数字券
            localStorage.setItem('publishCoupon', JSON.stringify(item.data))
            push('/addCoupon')
        } else if (item.type == 3) { // 商品
            localStorage.setItem('publishGoods', JSON.stringify(item.data))
            push('/addGoods')
        }
    }

    // 预览图片
    const previewMedia = (url) => {
        ImageViewer.show({ image: url })
    }

    return (
        <div className="ai-wrapper flex-column">
            <div className="content flex-1">
                {type == 1 && <div className="header">
                    <div className="logo"></div>
                    <div className="inner">
                        <div className="title">👋你好！我是AI闪猪侠</div>
                        <div>
                            我可以帮你搜索、答疑，你可以把向提问的问题通过输入文字或者文件发给我哦～<br />🎉来开启第一个话题吧！
                        </div>
                    </div>
                </div>}
                {type == 2 && <div className="header">
                    <div className="logo coupon"></div>
                    <div className="inner">
                        <div className="title">👋你好！我是数字券AI闪猪侠</div>
                        <div>在数一键生成AI数字券，相比前一代提升了80%，简单描述我满减活动，数字券AI闪猪侠一键自动为您生成数字券</div>
                    </div>
                </div>}
                {type == 3 && <div className="header">
                    <div className="logo coupon"></div>
                    <div className="inner">
                        <div className="title">👋你好！我是商品AI闪猪侠</div>
                        <div>商品AI闪猪侠，平台智能AI，一键上传商品，高效便捷，重塑商品上传新体验</div>
                    </div>
                </div>}
                <div className="chats">
                    {list.map(item => (<div key={item.id}>
                        {item.isMe ? <div className="item-bubble isMe">
                            <div className="item-media flex-shrink-0">
                                <Image className="item-avatar" src={info?.cover} round />
                            </div>
                            <div className="item-inner">
                                <div className="item-input">{item.content}</div>
                            </div>
                        </div> : <div className="ai-bubble">
                            <div className="ai-bubble-title flex-a-center">
                                {item.type == 2 && <div className="title">数字券生成</div>}
                                {item.type == 3 && <div className="title">商品生成</div>}
                                {item.loading
                                    ? <div className="bubble flex-a-center">
                                        <div className="icon-loading"></div>
                                        <div>生成中<ZTimer timestamp={item.startTime} />秒</div>
                                    </div>
                                    : <div className="bubble">用时{Math.round((item.endTime - item.startTime) / 1000)}秒</div>}
                            </div>
                            {!item.loading && <div className="ai-bubble-inner">
                                {item.type != 1 && !!item.data?.cover && <Image
                                    className="ai-bubble-img"
                                    src={item.data.cover}
                                    onClick={() => previewMedia(item.data.cover)}
                                />}
                                {item.type != 1 && !!item.data?.images && <Image
                                    className="ai-bubble-img"
                                    src={item.data.images}
                                    onClick={() => previewMedia(item.data.images)}
                                />}
                                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                {item.type != 1 && !!item.data?.video_url && <video
                                    className="ai-bubble-video"
                                    src={item.data.video_url}
                                    controls={true}
                                    autoPlay={false}
                                />}
                            </div>}
                            {item.type != 1 && !item.loading && !item.error && <div className="ai-bubble-footer flex">
                                <div className="ai-bubble-btn" onClick={() => {
                                    setContent(item.title)
                                    inputRef.current && inputRef.current.focus()
                                }}>
                                    <div className="icon-edit"></div>
                                    <div>重新编辑</div>
                                </div>
                                <div className="ai-bubble-btn" onClick={() => onRefresh(item)}>
                                    <div className="icon-refresh"></div>
                                    <div>再次生成</div>
                                </div>
                                <div className="ai-bubble-btn active" onClick={() => toPublish(item)}>
                                    <div className="icon-push"></div>
                                    <div>使用去发布</div>
                                </div>
                            </div>}
                        </div>}
                    </div>))}
                </div>
                <div id="bottom"></div>
            </div>
            <ZFooterBar style={{ '--height': '50px' }}>
                <form className="footer-input flex-1" onSubmit={(e) => {
                    e.preventDefault()
                    onConfirm()
                }}>
                    <input
                        ref={inputRef}
                        className="footer-input-inner"
                        placeholder={type == 1 ? '向闪猪侠提问...' : '请简单描述你想要创建的活动吧!'}
                        value={content}
                        onChange={e => setContent(e.target.value)}
                    />
                    <div className="footer-input-btn" onClick={onConfirm}></div>
                </form>
            </ZFooterBar>
        </div>
    )
}

export default observer(Ai)