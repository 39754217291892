import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Image, InfiniteScroll, Toast } from 'antd-mobile-v5'
import ZSearchBar from '@/components/zSearchBar'
import ZFooterBar from '@/components/zFooterBar'
import { delStore, getStoreList, getStoreNum } from '../../apis/store'
import { showConfirm } from '../../utils'
import './index.scss'

const StoreManage = () => {
    const { push } = useHistory()
    const page = useRef(1)
    const [status, setStatus] = useState(0)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [focus, setFocus] = useState(false)
    const [keywords, setKeywords] = useState('')
    const [nums, setNums] = useState({
        all_num: 0,
        already_add_num: 0,
        new_num: 0,
        reject_num: 0,
        unbind_num: 0,
    })
    const statusList = useMemo(() => ([
        { id: 0, name: `全部(${nums.all_num})` },
        { id: 1, name: `已添加(${nums.already_add_num})` },
        { id: 2, name: `待审核(${nums.new_num})` },
        { id: 3, name: `已驳回(${nums.reject_num})` },
        { id: 4, name: `已解绑(${nums.unbind_num})` },
    ]), [nums])

    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [status, keywords])

    // 获取订单数据
    const getData = async() => {
        try {
            const { data } = await getStoreNum()
            setNums(data)
        } catch (e) {
            console.log(e)
        }
    }

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getStoreList({
                page: _page,
                limit: 10,
                status: [undefined, 1, 0, 2, -1][status],
                keywords
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const onOper = async(id, status) => {
        const res = await showConfirm({
            title: '提示',
            content: `是否确认${status === 1 ? '关联' : '解绑'}该店铺？`,
            confirmText: '确认',
        })
        if (!res) return
        try {
            await delStore({
                id,
                status
            })
            Toast.show('操作成功')
            page.current = 1
            setList([])
            setHasMore(true)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="subStoreManage">
            <div className="header flex-space">
                {!focus ? <>
                    <div className="tabs flex flex-1">
                        {statusList.map(item => (
                            <div
                                key={item.id}
                                className={`item ${status === item.id ? 'active' : ''}`}
                                onClick={() => setStatus(item.id)}
                            >{item.name}</div>
                        ))}
                    </div>
                    <div className="search-box" onClick={() => setFocus(true)}>
                        <span className="icon-search"></span>
                        <span>搜索</span>
                    </div>
                </> : <ZSearchBar
                    value={keywords}
                    autoFocus
                    placeholder="请输入关键词搜索"
                    onConfirm={(v) => {
                        setKeywords(v)
                        setFocus(false)
                    }}
                />}
            </div>
            <div className="content">
                {list.map(item => (<div className="card" key={item.id}>
                    <div className="flex">
                        <div className="item-media flex-shrink-0">
                            <Image className="item-img" src={item.cover} />
                        </div>
                        <div className="item-inner flex-1">
                            <div className="item-title ell1">{item.short_name}</div>
                            <div className="item-subtl">累计销量 {item.total_order_count}<span>｜</span>30日销量 {item.thirty_day_order_count}<span>｜</span>类目 {item.store_category_name}</div>
                            <div className="item-subtl">电话: {item.mobile}</div>
                        </div>
                    </div>
                    <div className="item-footer flex">
                        {item.status === 0 && <div className="item-tips flex-1 flex-a-center">
                            <span className="icon-time2"></span>
                            <span>待审核</span>
                        </div>}
                        {item.status === 1 && <div className="item-tips succ flex-1 flex-a-center">
                            <span className="icon-succ"></span>
                            <span>已通过</span>
                        </div>}
                        {item.status === 2 && <div className="item-tips flex-1 flex-a-center">
                            <span className="icon-fail"></span>
                            <span>已驳回理由：{item.reject_reason}</span>
                        </div>}
                        {item.status === -1 && <div className="item-tips flex-1 flex-a-center">
                            <span className="icon-unbind"></span>
                            <span>已解绑</span>
                        </div>}
                        {item.status === 1 && <div className="item-btn" onClick={() => onOper(item.id, -1)}>解绑</div>}
                        {item.status === -1 && <div className="item-btn main" onClick={() => onOper(item.id, 1)}>关联</div>}
                    </div>
                </div>))}
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            </div>
            <ZFooterBar>
                <div className="footer-btn flex-1" onClick={() => push('/addSubStore')}>添加子店铺</div>
            </ZFooterBar>
        </div>
    )
}

export default StoreManage