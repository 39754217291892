import React, { useState } from 'react'
import { Toast } from 'antd-mobile-v5'
import { RightOutline } from 'antd-mobile-icons'
import Cookies from 'js-cookie'
import './index.scss'

const Setup = (props) => {
    const logOut = () => {
        Cookies.remove('token')
        Cookies.remove('mobile')
        Cookies.remove('store_id')
        Toast.show('退出登录成功')
        setTimeout(() => {
            props.history.push('/home')
        }, 1500)
    }

    return (
        <div className="setup-wrapper">
            <div className="item-cell flex-space" onClick={() => {
                props.history.push('/accountSecurity')
            }}>
                <div className="item-title">账号与安全</div>
                <div className="item-label flex-a-center">
                    {/* <span>主账号</span> */}
                    <RightOutline />
                </div>
            </div>
            <div className="item-cell flex-space" onClick={() => {
                props.history.push('/systemNotice')
            }}>
                <div className="item-title">通知订阅</div>
                <RightOutline />
            </div>
            <div className="item-cell flex-center" onClick={logOut}>
                <div className="item-title">退出登录</div>
            </div>
        </div>
    )
}

export default Setup