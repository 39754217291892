import React, { useEffect, useRef, useState } from 'react'
import { Image, InfiniteScroll, Toast } from 'antd-mobile-v5'
import RejectModal from '@/components/rejectModal'
import { getPlayService, playToExamine } from '@/apis/activity'
import { showConfirm } from '@/utils'

const statusList = [
    { id: 1, name: '待审核' },
    { id: 2, name: '已驳回' },
    { id: 4, name: '进行中' },
    { id: 6, name: '已结束' },
]

const PlayActivityList1 = () => {
    const [status, setStatus] = useState(0)
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const rejectModalRef = useRef()
    const rejectId = useRef()

    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [status])

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getPlayService({
                page: _page,
                limit: 10,
                status
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const setExamine = async(id, status, msg) => {
        try {
            const { data } = await playToExamine({
                id: id,
                status,
                examine_msg: msg
            })
            Toast.show('操作成功')
            page.current = 1
            setList([])
            setHasMore(true)
        } catch (e) {
            console.log(e)
        }
    }

    const onOk = async(id) => {
        const res = await showConfirm({
            title: '提示',
            content: '是否确认通过？'
        })
        if (!res) return
        setExamine(id, 1)
    }

    return (
        <div>
            <div className="content-card">
                <div className="status-tabs">
                    {statusList.map((item) => (
                        <div
                            key={item.id}
                            className={`item ${status === item.id ? 'active' : ''}`}
                            onClick={() => setStatus(item.id)}
                        >
                            <span className="item-name">{item.name}</span>
                        </div>
                    ))}
                </div>
                <div className="activity-list">
                    {list.map(item => (<div className="item-content" key={item.id}>
                        <div className="flex">
                            <div className="item-media flex-shrink-0">
                                <Image className="item-img" src={item.cover} />
                            </div>
                            <div className="item-inner flex-1">
                                <div className="flex-space">
                                    <div className="item-title ell1">{item.title}</div>
                                    {[1, 2].includes(item.status) && <div className="item-status erro">{item.status_html}</div>}
                                    {item.status === 4 && <div className="item-status succ">{item.status_html}</div>}
                                    {item.status === 6 && <div className="item-status">{item.status_html}</div>}
                                </div>
                                <div className="item-subtl flex-a-center">
                                    <div className="icon-time"></div>
                                    <div>{item.activity_time}</div>
                                </div>
                                <div className="item-subtl flex-a-center">
                                    <div className="icon-address"></div>
                                    <div>{item.address}</div>
                                </div>
                                <div className="item-subtl flex-a-center">
                                    <div className="icon-shenhe"></div>
                                    <div>{item.business_district_name}</div>
                                </div>
                                {item.status === 2 && <div className="item-desc">{item.examine_msg}</div>}
                                {item.involved_num > 0 && <div className="item-user flex-a-center">
                                    <div className="item-user-imgs">
                                        {item.involved_num > 3 && <div className="item-user-more">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>}
                                        {item.involved_user_list.map(t => (<img key={t.id} className="item-user-img" src={t.avatar} />))}
                                    </div>
                                    <div className="item-user-text">{item.involved_num}人已参加</div>
                                </div>}
                            </div>
                        </div>
                        {item.status === 1 && <div className="item-btns">
                            <div className="item-btn" onClick={() => {
                                rejectId.current = item.id
                                rejectModalRef.current && rejectModalRef.current.show()
                            }}>驳回</div>
                            <div className="item-btn main" onClick={() => onOk(item.id)}>通过</div>
                        </div>}
                        {[4,6].includes(item.status) && <div className="item-footer flex">
                            <div className="item-tag">
                                <div className="item-tag__text">消费<span className="num">{item.total_amount}</span></div>
                                <div className="item-tag__line"></div>
                                <div className="item-tag__text">领券<span className="num">{item.coupon_number}</span></div>
                                <div className="item-tag__line"></div>
                                <div className="item-tag__text">分享<span className="num">{item.share_number}</span></div>
                            </div>
                        </div>}
                    </div>))}
                </div>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            </div>
            <RejectModal ref={rejectModalRef} onConfirm={(v, hide) => {
                setExamine(rejectId.current, 2, v).finally(() => {
                    hide()
                })
            }} />
        </div>
    )
}

export default PlayActivityList1