import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ActivityList1 from './components/activityList1'
import ActivityList2 from './components/activityList2'
import ActivityList3 from './components/activityList3'
import PlayActivityList1 from './components/playActivityList1'
import PlayActivityList2 from './components/playActivityList2'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import qs from 'query-string'
import './index.scss'

const Activity = (props) => {
    const params = qs.parse(useLocation().search)
    const { info } = mainStore
    const [type, setType] = useState(params.type ? +params.type : 1)
    const [page, setPage] = useState(0)
    const tabList = useMemo(() => {
        const arr = [
            { id: 1, name: '我发布的' },
        ]
        if (page === 0) {
            arr.push({ id: 2, name: '我参与的' })
        }
        if (info.is_service_center === 1) {
            arr.push({ id: 3, name: '我处理的' })
        }
        return arr
    }, [info, page])

    const onPageChange = (v) => {
        setPage(v)
        if (type === 2) {
            setType(1)
        }
    }

    return (
        <div className="activity-wrapper">
            <div className="tabs">
                <div className={`item ${page === 0 ? 'active' : ''}`} onClick={() => onPageChange(0)}>联盟活动</div>
                <div className={`item ${page === 1 ? 'active' : ''}`} onClick={() => onPageChange(1)}>一起玩活动</div>
            </div>
            <div className="status">
                {tabList.map((item) => (
                    <div
                        key={item.id}
                        className={`item ${type === item.id ? 'active' : ''}`}
                        onClick={() => setType(item.id)}
                    >{item.name}</div>
                ))}
            </div>
            {page === 0 && <>
                {type === 1 && <ActivityList1 />}
                {type === 2 && <ActivityList2 />}
                {type === 3 && <ActivityList3 />}
            </>}
            {page === 1 && <>
                {type === 1 && <PlayActivityList1 />}
                {type === 3 && <PlayActivityList2 />}
            </>}
        </div>
    )
}

export default observer(Activity)