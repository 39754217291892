import http from './request'

// 系统培训、AI问答
export const getAiAnswer = (params) => http.get('/wechat/answer/ai-answer', params)

// 一起玩活动-Ai获取活动信息
export const getAiPlay = (params) => http.get('/wechat/activity-play/ai', params)

// 一起玩活动-Ai获取活动信息
export const getAiTask = (params) => http.get('/wechat/task-activity/ai', params)

// 联盟活动-AI获取联盟活动信息
export const getAiActivity = (params) => http.get('/wechat/activity/ai', params)

// 社区分享-Ai获取社区分享信息
export const getAiCommunity = (params) => http.get('/wechat/community-share/ai', params)

// 数字券-Ai获取数字券信息
export const getAiCoupon = (params) => http.get('/wechat/numeral-coupon/ai-numeral-coupon', params)

// 商品添加-AI获取商品信息
export const getAiProduct = (params) => http.get('/wechat/product/ai-product', params)