import React, { useState } from 'react'
import { RightOutline } from 'antd-mobile-icons'
import Cookies from 'js-cookie'
import './index.scss'

const AccountSecurity = ({ history }) => {
    return (
        <div className="accountSecurity">
            <div className="content">
                <div className="card">
                    <div className="item-cell flex-space">
                        <div className="item-title">绑定手机</div>
                        <div className="item-label">{Cookies.get('mobile')}</div>
                    </div>
                    <div className="item-cell flex-space" onClick={() => history.push('/editPassword')}>
                        <div className="item-title">修改密码</div>
                        <RightOutline />
                    </div>
                </div>
                <div className="card">
                    <div className="item-cell flex-space" onClick={() => history.push('/loginRecord')}>
                        <div className="item-title">最近登录记录</div>
                        <RightOutline />
                    </div>
                </div>
                <div className="card">
                    <div className="item-cell flex-space" onClick={() => history.push('/logOff')}>
                        <div className="item-title">注销账号</div>
                        <RightOutline />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSecurity