import React, { useEffect, useState } from 'react'

const ZTimer = ({ timestamp }) => {
    const [count, setCount] = useState(timestamp ? Math.floor((Date.now() - timestamp) / 1000) : 0)

    useEffect(() => {
        const t = setInterval(() => {
            setCount(v => v + 1)
        }, 1000)
        return () => {
            clearInterval(t)
        }
    }, [])

    return (
        <span>{count}</span>
    )
}

export default ZTimer