import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Toast } from 'antd-mobile-v5'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import { useMutation } from 'react-apollo'
import { SEND_SMS_CAPTCHA } from '@/graphql/login'
import { setPassword } from '../../apis/login'
import { formatPhoneNumber } from '../../utils'
import Cookies from 'js-cookie'
import './index.scss'

const useInput = () => {
    const [value, setValue] = useState('')

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onDel = () => {
        setValue('')
    }

    return [value, onChange, onDel]
}

const Item = ({ value, title, placeholder, onChange, onDel }) => {
    const [showEye, setShowEye] = useState(false)

    return (
        <div className="item flex">
            <div className="item-label">{title}</div>
            <div className="item-inner flex-1">
                <input
                    type={showEye ? 'text' : 'password'}
                    className="item-input"
                    value={value}
                    placeholder={placeholder}
                    maxLength={20}
                    autoComplete="new-password"
                    onChange={onChange}
                />
                {!!value && <span className="icon-close1 item-close" onClick={onDel}></span>}
            </div>
            <span className={`${showEye ? 'icon-eye-open' : 'icon-eye-close'} item-after`} onClick={() => setShowEye(v => !v)}></span>
        </div>
    )
}

const EditPassword = (props) => {
    const { info } = mainStore
    const [code, onCodeChange] = useInput()
    const timer = useRef()
    const [codeSecond, setCodeSecond] = useState(0)
    const [password, onPasswordChange, onPasswordDel] = useInput()
    const [password1, onPassword1Change, onPassword1Del] = useInput()
    const [password2, onPassword2Change, onPassword2Del] = useInput()
    const disabled = useMemo(() => {
        return (!code || !password || !password1 || !password2)
    }, [code, password, password1, password2])

    useEffect(() => {
        if (!info.id) {
            mainStore.getInfo()
        }
    }, [])

    const [sendSmsCaptcha] = useMutation(SEND_SMS_CAPTCHA, {
        // 请求带的参数
        variables: {
            mobile: info.mobile,
            type: 2,
        },
        // 请求失败异常的回调
        onError(e) {
            setCodeSecond(0)
            clearInterval(timer.current)
        },
    })

    const getSmsCode = () => {
        if (!info.mobile) return Toast.show('手机号不存在')
        if (!codeSecond) {
            sendSmsCaptcha()
            let count = 60
            setCodeSecond(count)
            clearInterval(timer.current)
            timer.current = setInterval(() => {
                if (count > 0) {
                    count--
                    setCodeSecond(count)
                }
                if (count === 0) {
                    clearInterval(timer.current)
                }
            }, 1000)
        }
    }

    const logOut = () => {
        Cookies.remove('token')
        Cookies.remove('mobile')
        Cookies.remove('store_id')
        setTimeout(() => {
            props.history.push('/home')
        }, 1500)
    }

    const onSubmit = async() => {
        if (disabled) return
        if (!info.mobile) {
            return Toast.show('手机号不存在')
        } else if (password1 != password2) {
            return Toast.show('确认密码与新密码不一致')
        }
        try {
            const params = {
                mobile: info.mobile,
                code: code,
                old_password: password,
                password: password1,
                check_password: password2,
            }
            const { data } = await setPassword(params)
            Toast.show('修改密码成功')
            logOut()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="editPassword">
            <div className="content">
                <div className="header">
                    <div className="title">请为您的手机账号</div>
                    <div className="phone">{info.mobile ? formatPhoneNumber(info.mobile, ' ') : ''}</div>
                    <div className="title">设置一个新密码</div>
                </div>
                <div className="card">
                    <div className="item flex m20">
                        <div className="item-inner flex-1">
                            <input
                                type="text"
                                className="item-input"
                                placeholder="请输入验证码"
                                autoComplete="off"
                                value={code}
                                onChange={onCodeChange}
                            />
                        </div>
                        <div className="item-code flex-shrink-0" onClick={getSmsCode}>
                            {!!codeSecond ? codeSecond + 's后重新获取' : '获取验证码'}
                        </div>
                    </div>
                    <Item
                        title="当前密码"
                        placeholder="请输入当前密码"
                        value={password}
                        onChange={onPasswordChange}
                        onDel={onPasswordDel}
                    />
                    <Item
                        title="新密码"
                        placeholder="请输入新密码"
                        value={password1}
                        onChange={onPassword1Change}
                        onDel={onPassword1Del}
                    />
                    <Item
                        title="确认密码"
                        placeholder="请再次输入密码"
                        value={password2}
                        onChange={onPassword2Change}
                        onDel={onPassword2Del}
                    />
                    <div className={`card-btn ${disabled ? 'disabled' : ''}`} onClick={onSubmit}>确认</div>
                </div>
            </div>
        </div>
    )
}

export default observer(EditPassword)