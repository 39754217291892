import React, { useRef, useState } from 'react'
import './index.scss'
import { getLoginHistory } from '../../apis/login'
import { InfiniteScroll } from 'antd-mobile-v5'

const LoginRecord = () => {
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getLoginHistory({
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    return (
        <div className="loginRecord">
            <div className="content">
                <div className="tips-box">若发现非本人操作的登录记录，请及时修改密码，确保账户安全</div>
                <div className="card">
                    {list.map(item => (<div className="item-cell" key={item.id}>
                        <div className="item-title">{item.model || '未知设备'}</div>
                        <div className="item-subtl">{item.login_date}{item.province ? `｜${item.province} ${item.city}` : ''}</div>
                    </div>))}
                </div>
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
        </div>
    )
}

export default LoginRecord