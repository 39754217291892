import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import './index.scss'

const LogOff = () => {
    const { info } = mainStore

    useEffect(() => {
        if (!info.id) {
            mainStore.getInfo()
        }
    }, [])

    return (
        <div className="logOff">
            <div className="title">请联系客服注销账号</div>
            <a href={`tel:${info.platform_service_tel}`} className="logOff-btn">联系客服</a>
        </div>
    )
}

export default observer(LogOff)