import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Image, Toast } from 'antd-mobile-v5'
import RejectModal from '../../components/rejectModal'
import ZFooterBar from '../../components/zFooterBar'
import './index.scss'
import qs from 'query-string'
import { getStoreJoinGoodsList, storeJoinExamine } from '../../apis/activity'
import { showConfirm } from '../../utils'

const StoreExamine = (props) => {
    const { id, storeId } = qs.parse(useLocation().search)
    const [list, setList] = useState([])
    const [info, setInfo] = useState({
        product_num: 0
    })
    const rejectModalRef = useRef()

    useEffect(() => {
        getList()
    }, [])

    async function getList() {
        try {
            const { data } = await getStoreJoinGoodsList({
                activity_id: id,
                store_id: storeId
            })
            const { product_list, ..._data } = data
            setList(product_list)
            setInfo(_data)
        } catch (e) {
            console.log(e)
        }
    }

    const setStatus = async(status, msg) => {
        try {
            const { data } = await storeJoinExamine({
                activity_id: id,
                store_id: storeId,
                status,
                examine_msg: msg
            })
            Toast.show('操作成功')
            getList()
        } catch (e) {
            console.log(e)
        }
    }
    const onOk = async() => {
        const res = await showConfirm({
            title: '提示',
            content: '是否确认通过该商家？'
        })
        if (!res) return
        setStatus(1)
    }

    return (
        <div className="storeExamine">
            <div className="header flex-space">
                <div className="header-title">共{info.product_num}个活动商品</div>
                {!!info.store && <div className="store-info flex-a-center">
                    <img className="item-img" src={info.store.cover} alt="" />
                    <div className="item-name">{info.store.name}</div>
                </div>}
            </div>
            <div className="list">
                {!!info.store_status && <div className="tips-box">
                    {info.store_status === 1 && <div className="tips-inner flex-a-center succ">
                        <span className="icon-succ"></span>
                        <span className="name">审核通过</span>
                    </div>}
                    {info.store_status === 2 && <div className="tips-inner flex-a-center erro">
                        <span className="icon-fail"></span>
                        <span className="name">已驳回：{info.store_examine_msg}</span>
                    </div>}
                </div>}
                {list.map(item => (<div className="item-content flex" key={item.id}>
                    <div className="item-media flex-shrink-0">
                        <Image className="item-img" src={item.cover} />
                    </div>
                    <div className="item-inner flex-1">
                        <div className="item-title ell1">{item.name}</div>
                        <div className="item-tags flex">
                            <div className="item-tag">{item.product_category_name}</div>
                        </div>
                        <div className="flex-space">
                            <div className="item-amount">¥{item.price}</div>
                            <div className="item-number">库存：{item.stock}</div>
                        </div>
                    </div>
                </div>))}
            </div>
            {info.store_status === 0 && <ZFooterBar>
                <div className="footer-btn flex-1" onClick={() => {
                    rejectModalRef.current && rejectModalRef.current.show()
                }}>驳回</div>
                <div className="footer-btn main flex-1" onClick={onOk}>通过</div>
            </ZFooterBar>}
            <RejectModal ref={rejectModalRef} onConfirm={(v, hide) => {
                setStatus(2, v).finally(() => {
                    hide()
                })
            }} />
        </div>
    )
}

export default StoreExamine