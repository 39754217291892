import classNames from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CalendarPicker, Image, InfiniteScroll, Popup, Toast } from 'antd-mobile-v5'
import ZSearchBar from '../../components/zSearchBar'
import qs from 'query-string'
import { getOrderData } from '../../apis/home'
import { diningOut, getOrderList, isOrderInProgress, ORDER_STATUS } from '../../apis/order'
import { copy, showConfirm, timestampToString } from '../../utils'
import moment from 'moment'
import './index.scss'

const orderMap = {
    1: 'take_out_data',
    2: 'take_out_self_pickup_data',
    3: 'logistics_data',
    4: 'scan_code_to_order_data'
}
const dateTypeOptions = [
    { value: 'today', label: '今日' },
    { value: 'yesterday', label: '昨日' },
    { value: 'sevenDay', label: '近7日' },
    { value: 'thirtyDay', label: '近30天' },
    { value: 'ninetyDay', label: '近90天' },
    { value: 'customize', label: '自定义' },
]
const minDate = moment().subtract(3, 'months').date(1)

const OrderManage = () => {
    const { push } = useHistory()
    const { search, pathname } = useLocation()
    const { status: _status } = qs.parse(search)
    const [status, setStatus] = useState(_status ? +_status : 0)
    const [dateType, setDateType] = useState()
    const [dateVisible, setDateVisible] = useState(false)
    const [dates, setDates] = useState(null)
    const [focus, setFocus] = useState(false)
    const [keywords, setKeywords] = useState('')
    const [visible, setVisible] = useState(false)
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [datas, setDatas] = useState({
        take_out_data: {},
        take_out_self_pickup_data: {},
        logistics_data: {},
        scan_code_to_order_data: {}
    })
    const type = useMemo(() => {
        const paths = ['', '/takeoutDelivery', '/takeoutSelfHelp', '/expressDelivery', '/scanCodeOrder']
        return paths.findIndex(t => t === pathname)
    }, [])
    const type2 = useMemo(() => {
        const paths = ['/expressDelivery', '/takeoutDelivery', '/takeoutSelfHelp', '/scanCodeOrder']
        return paths.findIndex(t => t === pathname)
    }, [])
    const statusList = useMemo(() => {
        const _data = datas[orderMap[type]]
        switch (type) {
        case 1:
        case 3:
            return [
                { id: 0, name: '今日订单', value: _data.today_order_num },
                { id: 1, name: '全部订单', value: _data.all_order_num },
                { id: 2, name: '未支付', value: _data.no_pay_order_num },
                { id: 3, name: '待发货', value: type === 1 ? _data.pend_shipment_num : _data.pending_shipment_num },
                { id: 4, name: '配送中', value: _data.in_progress_num },
                { id: 5, name: '配送异常', value: _data.delivery_exception_num },
                { id: 6, name: '已完成', value: _data.success_num },
                { id: 7, name: '退款中', value: _data.refunding_num },
                { id: 8, name: '已退款', value: _data.refunded_num },
                { id: 9, name: '已关闭', value: _data.close_num },
            ]
        case 2:
            return [
                { id: 0, name: '今日订单', value: _data.today_order_num },
                { id: 1, name: '全部订单', value: _data.all_order_num },
                { id: 2, name: '未支付', value: _data.no_pay_order_num },
                { id: 3, name: '制作中', value: _data.in_production_num },
                { id: 4, name: '待核销', value: _data.pending_verification_num },
                { id: 6, name: '已完成', value: _data.success_num },
                { id: 7, name: '退款中', value: _data.refunding_num },
                { id: 8, name: '已退款', value: _data.refunded_num },
                { id: 9, name: '已关闭', value: _data.close_num },
            ]
        case 4:
            return [
                { id: 0, name: '今日订单', value: _data.today_order_num },
                { id: 1, name: '全部订单', value: _data.all_order_num },
                { id: 2, name: '未支付', value: _data.no_pay_order_num },
                { id: 3, name: '制作中', value: _data.in_production_num },
                { id: 4, name: '待取餐', value: _data.wait_pick_up_meals },
                { id: 6, name: '已完成', value: _data.success_num },
                { id: 7, name: '退款中', value: _data.refunding_num },
                { id: 8, name: '已退款', value: _data.refunded_num },
                { id: 9, name: '已关闭', value: _data.close_num },
            ]
        default:
            return []
        }
        
    }, [datas])
    const tabs = useRef()

    useEffect(() => {
        getData().finally(() => {
            tabs.current && tabs.current.scrollTo({
                left: document.getElementById('tabItem-'+status).offsetLeft,
                behavior: 'smooth'
            })
        })
    }, [])
    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [status])

    // 获取订单数据
    const getData = async() => {
        try {
            const { data } = await getOrderData({ type: type })
            setDatas(data)
        } catch (e) {
            console.log(e)
        }
    }

    async function loadMore() {
        try {
            const _page = page.current
            const params = {
                page: _page,
                limit: 10,
                type: type2,
                status,
                date_type: dateType,
            }
            if (dateType === 'customize' && dates) {
                params.start_time = timestampToString(dates[0], 1)
                params.end_time = timestampToString(dates[1], 1)
            }
            const { data } = await getOrderList(params)
            data.data.forEach((item) => {
                const t = (48*60*60*1000) - (Date.now() - item.pay_at * 1000)
                let isRefund = false
                if (isOrderInProgress(item.status) || (item.status === 1 && t > 0)) {
                    isRefund = true
                }
                item.isRefund = isRefund
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const copyAddress = (item) => {
        copy(`${item.address.name} ${item.address.mobile} ${item.address.province}${item.address.city}${item.address.area}${item.address.address}`)
    }

    // 出餐
    const onDiningOut = (id) => {
        showConfirm({
            title: '是否确认出餐？',
            content: '请确认菜品无误后再出餐',
            confirmText: '确认',
            onConfirm: async () => {
                try {
                    const res = await diningOut(id)
                    if (res.errors.code === 1) {
                        Toast.show('出餐成功')
                        page.current = 1
                        setList([])
                        setHasMore(true)
                    }
                } catch(e) {
                    console.log(e)
                }
            }
        })
    }

    const onSearch = () => {
        page.current = 1
        setList([])
        setHasMore(true)
        setVisible(false)
    }

    const onReset = () => {
        setDateType(undefined)
        setDates(null)
        onSearch()
    }

    return (
        <div className="orderManage">
            <div className="header flex-space">
                {!focus ? <>
                    <div ref={tabs} className="tabs flex flex-1">
                        {statusList.map(item => (
                            <div
                                key={item.id}
                                id={`tabItem-${item.id}`}
                                className={`item ${status === item.id ? 'active' : ''}`}
                                onClick={() => setStatus(item.id)}
                            >{item.name}({item.value || 0})</div>
                        ))}
                    </div>
                    <div className="search-box" onClick={() => setFocus(true)}>
                        <span className="icon-search"></span>
                        <span>搜索</span>
                    </div>
                </> : <ZSearchBar
                    value={keywords}
                    autoFocus
                    placeholder="请输入内容"
                    onConfirm={(v) => {
                        setKeywords(v)
                        setFocus(false)
                    }}
                />}
            </div>
            <div className="sort-box flex flex-end">
                <div className={`item flex-a-center ${visible ? 'active' : ''}`} onClick={() => {
                    setVisible(!visible)
                }}>
                    <span style={{ marginRight: 3 }}>筛选</span>
                    <span className={`icon-sort ${visible ? 'active' : ''}`}></span>
                </div>
            </div>
            <div className="content">
                {list.map(item => (<div className="card" key={item.id} onClick={() => {
                    push(`/orderDetail?id=${item.id}`)
                }}>
                    <div className="card-header flex-space">
                        <div className="userInfo flex-a-center">
                            <img className="userInfo-img" src={item.user?.avatar} />
                            <div className="userInfo-name">{item.user?.username || '微信用户'}</div>
                            <span className="icon-message"></span>
                        </div>
                        {(type2 === 0 || type2 === 1) && <div className={classNames({
                            'item-status': true,
                            erro: [0,2,5,8,9,11].includes(item.status),
                            succ: [4].includes(item.status),
                        })}>{ORDER_STATUS[item.status]}</div>}
                        {(type2 === 2 || type2 === 3) && <div className={classNames({
                            'item-status': true,
                            erro: [0,5].includes(item.status),
                            succ: [2,4].includes(item.status),
                        })}>{({ 2: '制作中', 4: type2 === 3 ? '待取餐' : '待核销' })[item.status] || ORDER_STATUS[item.status]}</div>}
                    </div>
                    <div className="card-inner">
                        {item.order_items.map(good => (<div className="item-good flex" key={good.id}>
                            <div className="item-good__media flex-shrink-0">
                                <Image className="item-good__img" src={good.product_cover} />
                            </div>
                            <div className="item-good__inner flex-1">
                                <div className="item-good__title ell2">{good.product_name}</div>
                                <div className="item-good__subtl">{good.product_spec_val}</div>
                            </div>
                            <div className="item-good__after flex-column align-end">
                                <div className="item-good__amount">¥{good.product_price}</div>
                                <div className="item-good__number">x{good.number}</div>
                            </div>
                        </div>))}
                        <div className="item-total">实收:<span className="b">¥{item.actual_amount}</span></div>
                        <div className="item-texts">
                            {[0, 1].includes(type2) && <div className="item-cell flex">
                                <div className="item-cell__label">收货信息</div>
                                {!!item.address ? <div className="item-cell__inner flex-1">
                                    <div className="flex-a-center" style={{ marginBottom: 3 }}>
                                        <span>{item.address.name} {item.address.mobile}</span>
                                        <span className="icon-copy" style={{ marginLeft: 6 }} onClick={(e) => {
                                            e.stopPropagation()
                                            copyAddress(item)
                                        }}></span>
                                    </div>
                                    <div>{item.address.province} {item.address.city} {item.address.area} {item.address.address}</div>
                                </div> : <div className="item-cell__inner flex-1">-</div>}
                            </div>}
                            {[2, 3].includes(type2) && <div className="item-cell flex">
                                <div className="item-cell__label">预留电话</div>
                                {!!item.address ? <div className="item-cell__inner flex-1 flex-a-center">
                                    <span>{item.address.mobile}</span>
                                    <span className="icon-phone1" style={{ marginLeft: 6 }}></span>
                                </div> : <div className="item-cell__inner flex-1">-</div>}
                            </div>}
                            <div className="item-cell flex">
                                <div className="item-cell__label">买家留言</div>
                                <div className="item-cell__inner flex-1">{item.remake || '-'}</div>
                            </div>
                        </div>
                        {(item.isRefund || ([0,2,3].includes(type2) && item.status === 2)) && <div className="item-footer flex flex-end">
                            {item.isRefund && <div className="item-btn" onClick={(e) => {
                                e.stopPropagation()
                                push(`/refund?id=${item.id}`)
                            }}>退款</div>}
                            {type2 === 0 && item.status === 2 && <div className="item-btn main" onClick={(e) => {
                                e.stopPropagation()
                                push(`/delivery?id=${item.id}`)
                            }}>发货</div>}
                            {[2,3].includes(type2) && item.status === 2 && <div className="item-btn main" onClick={(e) => {
                                e.stopPropagation()
                                onDiningOut(item.id)
                            }}>出餐</div>}
                        </div>}
                    </div>
                </div>))}
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            </div>
            <Popup
                visible={visible}
                className="filter-popup"
                position="top"
                onMaskClick={() => setVisible(false)}
            >
                <div className="sort-popup">
                    <div className="sort-popup-title">下单时间<span>列表最多展示近90日内订单</span></div>
                    <div className="sort-popup-tags">
                        {dateTypeOptions.map(item => (
                            <div
                                key={item.value}
                                className={`sort-popup-tag ${item.value === dateType ? 'active' : ''}`}
                                onClick={() => setDateType(item.value)}
                            >{item.label}</div>
                        ))}
                    </div>
                    {dateType === 'customize' && <div className="sort-popup-date flex-a-center" onClick={() => setDateVisible(true)}>
                        <div className="flex-1">
                            <input type="text" placeholder="开始日期" readOnly value={dates ? timestampToString(dates[0], 1) : ''} />
                        </div>
                        <span className="icon-jiantou" style={{ margin: '0 10px' }}></span>
                        <div className="flex-1">
                            <input type="text" placeholder="结束日期" readOnly value={dates ? timestampToString(dates[1], 1) : ''} />
                        </div>
                    </div>}
                    <div className="sort-popup-footer">
                        <div className="sort-popup-btn" onClick={onReset}>重置</div>
                        <div className="sort-popup-btn main flex-1" onClick={onSearch}>确认</div>
                    </div>
                </div>
            </Popup>
            <CalendarPicker
                visible={dateVisible}
                selectionMode='range'
                min={minDate.toDate()}
                value={dates}
                onClose={() => setDateVisible(false)}
                onMaskClick={() => setDateVisible(false)}
                onChange={val => {
                    // console.log(val)
                    setDates(val)
                }}
            />
        </div>
    )
}

export default OrderManage