import React, { useImperativeHandle, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DotLoading, Image, InfiniteScroll, Toast } from 'antd-mobile-v5'
import ZEmpty from '../zEmpty'
import ZModal from '../zModal'
import './index.scss'
import { getActivityGoodsList } from '../../apis/activity'

const InfiniteScrollContent = ({ hasMore, isEmpty }) => {
    return (<>
        {hasMore ? (<>
            <span>加载中</span>
            <DotLoading />
        </>) : (
            !isEmpty ? <span>没有更多了</span> : <ZEmpty text="你还没有商品，点击上传商品立即参与活动" style={{ paddingTop: 0 }} />
        )}
    </>)
}

const GoodsModal = React.forwardRef(({ title, type = 'multiple', visible, onClose, onConfirm }, ref) => {
    const history = useHistory()
    const page = useRef(1)
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [checkedList, setCheckedList] = useState([])

    const loadMore = async() => {
        try {
            const _page = page.current
            const { data } = await getActivityGoodsList({
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            setTotal(data.total)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const onItemChange = (item) => {
        if (type === 'multiple') {
            const index = checkedList.findIndex(t => t.product_id === item.product_id)
            if (index === -1) {
                setCheckedList([...checkedList, item])
            } else {
                const _list = [...checkedList]
                _list.splice(index, 1)
                setCheckedList(_list)
            }
        } else {
            setCheckedList([item])
        }
    }

    const onSubmit = () => {
        if (!checkedList.length) {
            Toast.show('请选择商品')
            return
        }
        onConfirm && onConfirm(checkedList)
        onClose && onClose()
    }

    const toAdd = () => {
        history.push('/addGoods')
    }

    useImperativeHandle(ref, () => {
        return {
            reset: (v) => {
                setCheckedList(v || [])
            }
        }
    })

    return (
        <ZModal
            title={title}
            className="goods-modal"
            visible={visible}
            onClose={onClose}
        >
            {total > 0 && total < 3 && <div className="goods-modal-title">产品似乎有点少，增加商品会增加订单量喔～</div>}
            <div className="goods-modal-inner">
                <div className="goods-modal-list">
                    {list.map((item, index) => (<div className="item-content flex" key={item.product_id}>
                        <div className="item-media flex-shrink-0">
                            <Image className="item-img" src={item.cover} />
                        </div>
                        <div className="item-inner flex-1">
                            <div className="item-title ell2">{item.product_name}</div>
                            <div className="item-amount">¥{item.price}</div>
                        </div>
                        <div className={`icon-checkbox item-checkbox ${checkedList.some(t => t.product_id === item.product_id) ? 'active' : ''}`} onClick={() => onItemChange(item, index)}></div>
                    </div>))}
                </div>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50}>
                    <InfiniteScrollContent hasMore={hasMore} isEmpty={!list.length} />
                </InfiniteScroll>
            </div>
            <div className="goods-modal-footer">
                {total === 0 && <div className="goods-modal-btn main" onClick={toAdd}>上传商品</div>}
                {total > 0 && total < 3 && <>
                    <div className="goods-modal-btn" onClick={toAdd}>上传商品</div>
                    <div className="goods-modal-btn main" onClick={onSubmit}>不了，已选好</div>
                </>}
                {total >= 3 && <div className="goods-modal-btn main" onClick={onSubmit}>确定</div>}
            </div>
        </ZModal>
    )
})

export default GoodsModal