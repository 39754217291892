import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Image, ImageViewer, InfiniteScroll, Modal, Toast } from 'antd-mobile-v5'
import RejectModal from '@/components/rejectModal'
import { getCommunityList, setCommunityExamine, setCommunityReportExamine } from '@/apis/community'
import { showConfirm } from '@/utils'
import './index.scss'

const CommunityManage = () => {
    const [status, setStatus] = useState(0)
    const [counts, setCounts] = useState({
        wait_count: 0,
        pass_count: 0,
        reject_count: 0,
        report_count: 0,
    })
    const tabsList = useMemo(() => {
        return [
            { id: 0, name: '待审核', number: counts.wait_count },
            { id: 1, name: '已通过', number: counts.pass_count },
            { id: 2, name: '已驳回', number: counts.reject_count },
            { id: 3, name: '举报处理', number: counts.report_count }
        ]
    }, [counts])
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const rejectModalRef = useRef()
    const rejectId = useRef()
    const isReport = useRef(0) // 是否是举报
    const [rejectTitle, setRejectTitle] = useState('')


    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [status])

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getCommunityList({
                status,
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            setCounts({
                wait_count: data.wait_count,
                pass_count: data.pass_count,
                reject_count: data.reject_count,
                report_count: data.report_count,
            })
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    // 通过｜驳回｜下架
    const setExamine = async(id, status, msg) => {
        try {
            const { data } = await setCommunityExamine({
                id: id,
                status,
                examine_msg: msg
            })
            Toast.show('操作成功')
            page.current = 1
            setList([])
            setHasMore(true)
        } catch (e) {
            console.log(e)
        }
    }

    // 通过
    const onOk = async(id) => {
        const res = await showConfirm({
            title: '提示',
            content: '是否确认通过？'
        })
        if (!res) return
        setExamine(id, 1)
    }

    const showViewer = (t) => {
        t.is_video === 0 ? ImageViewer.show({ image: t.url }) : Modal.show({
            content: (<div>
                <video
                    src={t.url}
                    controls
                    style={{ display: 'block', width: '100%', height: 200, background: '#000' }}
                ></video>
            </div>),
            closeOnMaskClick: true,
        })
    }

    // 举报下架
    const setReportExamine = async(report_id, status, msg) => {
        try {
            const { data } = await setCommunityReportExamine({
                report_id,
                status,
                examine_msg: msg
            })
            Toast.show('操作成功')
            page.current = 1
            setList([])
            setHasMore(true)
        } catch (e) {
            console.log(e)
        }
    }

    // 举报忽略
    const onIgnore = async(report_id) => {
        const res = await showConfirm({
            title: '提示',
            content: '是否确认忽略该举报？'
        })
        if (!res) return
        setReportExamine(report_id, 2)
    }

    const onBeforeReject = (id, title, is_report = 0) => {
        rejectId.current = id
        isReport.current = is_report
        rejectModalRef.current && rejectModalRef.current.show()
        setRejectTitle(title)
    }

    return (
        <div className="communityManage">
            <div className="header">
                <div className="tabs flex">
                    {tabsList.map(item => (
                        <div
                            key={item.id}
                            className={`item flex-a-center ${status === item.id ? 'active' : ''}`}
                            onClick={() => setStatus(item.id)}
                        >
                            <span>{item.name}</span>
                            {item.number > 0 && <span className="dot">{item.number}</span>}
                        </div>
                    ))}
                </div>
            </div>
            <div className="community-list">
                {list.map(item => (item.status != 3 ? (
                    <div className="item-content" key={item.id}>
                        <div className="flex align-start">
                            <div className="item-media flex-shrink-0">
                                <Image className="item-img" src={item.avatar} />
                            </div>
                            <div className="item-inner flex-1">
                                <div className="item-title">{item.nickname}</div>
                                <div className="item-text">{item.content}</div>
                                <div className="item-pics">
                                    {(item.images || []).map((t, i) => (
                                        <div
                                            key={i}
                                            className={`item-pic ${(item.images || []).length === 1 ? 'auto' : ''}`}
                                            onClick={() => showViewer(t)}
                                        >
                                            <Image className="item-pic__inner" src={t.is_video === 1 ? `${t.url}?x-oss-process=video/snapshot,t_1050,f_jpg` : t.url} />
                                            {t.is_video === 1 && <div className="icon-play"></div>}
                                        </div>
                                    ))}
                                </div>
                                {item.status === 2 && <div className="item-erro">
                                    <div className="flex-a-center">
                                        <div className="icon-fail"></div>
                                        <div className="flex-1 ell1">原因：{item.examine_msg}</div>
                                    </div>
                                    {!!item.report_user_name && <div className="flex-a-center">
                                        <div className="icon-wran"></div>
                                        <div className="flex-1 ell1">举报人：{item.report_user_name}</div>
                                    </div>}
                                </div>}
                            </div>
                        </div>
                        {item.status === 0 && <div className="item-footer flex flex-end">
                            <div className="item-btn" onClick={() => onBeforeReject(item.id, '驳回意见')}>驳回</div>
                            <div className="item-btn main" onClick={() => onOk(item.id)}>通过</div>
                        </div>}
                        {item.status === 1 && <div className="item-footer flex flex-end">
                            <div className="item-btn main" onClick={() => onBeforeReject(item.id, '下架原因')}>下架</div>
                        </div>}
                    </div>
                ) : (
                    <div className="item-content" key={item.report_id}>
                        <div className="item-header flex-a-center">
                            <div className="item-media flex-shrink-0">
                                <Image className="item-img" src={item.report_user_avatar} />
                            </div>
                            <div className="flex-1">
                                <div className="item-title flex-a-center">
                                    <span>{item.report_user_name}</span>
                                    <span className="item-phone">{item.report_user_mobile}</span>
                                </div>
                                <div className="item-subtl">{item.report_created_at}</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="icon-wran"></div>
                            <div className="flex-1">
                                <div className="item-report">{item.report_content}</div>
                                <div className="item-bg flex align-start">
                                    <div className="item-media flex-shrink-0">
                                        <Image className="item-img" src={item.avatar} />
                                    </div>
                                    <div className="item-inner flex-1">
                                        <div className="item-title">{item.nickname}</div>
                                        <div className="item-subtl">{item.publish_at}</div>
                                        <div className="item-text">{item.content}</div>
                                        <div className="item-pics">
                                            {(item.images || []).map((t, i) => (
                                                <div
                                                    key={i}
                                                    className={`item-pic ${(item.images || []).length === 1 ? 'auto' : ''}`}
                                                    onClick={() => showViewer(t)}
                                                >
                                                    <Image className="item-pic__inner" src={t.is_video === 1 ? `${t.url}?x-oss-process=video/snapshot,t_1050,f_jpg` : t.url} />
                                                    {t.is_video === 1 && <div className="icon-play"></div>}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-footer flex flex-end">
                            <div className="item-btn" onClick={() => onIgnore(item.report_id)}>忽略</div>
                            <div className="item-btn main" onClick={() => onBeforeReject(item.report_id, '下架原因', 1)}>下架</div>
                        </div>
                    </div>
                )))}
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />

            <RejectModal
                ref={rejectModalRef}
                title={rejectTitle}
                placeholder={rejectTitle === '下架原因' ? '请输入下架原因' : '请输入审核意见'}
                onConfirm={(v, hide) => {
                    if (isReport.current == 1) {
                        setReportExamine(rejectId.current, 1, v).finally(() => {
                            hide()
                        })
                    } else {
                        setExamine(rejectId.current, 2, v).finally(() => {
                            hide()
                        })
                    }
                }}
            />
        </div>
    )
}

export default CommunityManage