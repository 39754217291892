import http from './request'

// 店铺管理-列表类型数量
export const getStoreNum = () => http.get('/store/mobile-store/list-num')

// 店铺管理-列表
export const getStoreList = (params) => http.get('/store/mobile-store/list', params)

// 解绑、关联
export const delStore = (params) => http.post('/store/shop/del-store', params)

// 添加子店铺
export const addSonStore = (params) => http.post('/store/mobile-shop/add-son-store', params)

// 商家类目
export const getCategoryList = () => http.get('/store/mobile-shop/store-category')